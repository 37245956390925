<template>
  <div class="statusTag">
    <span
      class="px-2 rounded text-sm font-medium action-status"
      :class="displayClass"
      v-text="displayText"
    ></span>
  </div>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'

export default {
  name: 'AppActionStatus',
  props: {
    status: {
      type: String,
      default: 'pending',
    },
  },
  setup(props) {
    const displayText = ref('')
    const displayClass = ref('')
    const stateStatus = computed(() => props.status)

    const assignTextAndClass = (status) => {
      if (status === 'pending') {
        displayText.value = 'Pending'
        displayClass.value = 'pending'
        return
      }
      if (status === 'individualPending') {
        displayText.value = 'Pending'
        displayClass.value = 'individualPending'
        return
      }
      if (status === 'completed') {
        displayText.value = 'Ready to submit'
        displayClass.value = 'completed'
        return
      }
      if (status === 'owner') {
        displayText.value = 'Owner'
        displayClass.value = 'authorizedPersonnel'
        return
      }
      if (status === 'director') {
        displayText.value = 'Director'
        displayClass.value = 'authorizedPersonnel'
        return
      }

      displayText.value = status
      displayClass.value = 'authorizedPersonnel'
    }

    assignTextAndClass(props.status)

    watch(stateStatus, (status) => {
      assignTextAndClass(status)
    })

    return {
      displayText,
      displayClass,
      assignTextAndClass,
    }
  },
}
</script>

<style lang="postcss" scoped>
.action-status {
  padding: 2.75px 8.40625px;
}

.pending {
  color: #c33f13;
  background-color: #fcefd9;
}

.completed {
  color: #08815c;
  background-color: #d9feeb;
}

.authorizedPersonnel {
  color: #006ce0;
  background-color: #f2f7fe;
  @apply text-xs;
}

.individualPending {
  color: #774913;
  background-color: #fff5d9;
  @apply text-xs .font-semibold;
}
.statusTag {
  display: inline-block;
  padding-right: 5px;
}
</style>
