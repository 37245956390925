import { computed, getCurrentInstance } from '@vue/composition-api'
export function useComponentId(suffix) {
  let { uid } = getCurrentInstance()
  return computed(() => {
    return suffix ? `${uid}-${suffix}` : `${uid}`
  })
}

export const useComponentRefs = () => {
  const instance = getCurrentInstance()
  return {
    componentRefs: computed(() => instance.refs)
  }
}