<template>
  <div :class="containerClass">
    <h1 :class="[headingTitleClass, hideSubHeadingClass]" :data-private="dataPrivate">
      {{ heading }}
      <AppActionStatus v-if="status.length > 0" :status="status" />
    </h1>
    <template v-if="!hideSubHeading">
      <h4 v-if="subHeading.length > 0" :class="subHeadingClass">
        {{ subHeading }}
      </h4>
    </template>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import AppActionStatus from '@/components/AppActionStatus/AppActionStatus'

export default {
  components: {
    AppActionStatus,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    subHeading: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    isOuter: {
      type: Boolean,
      default: false,
    },
    hideSubHeading: {
      type: Boolean,
      default: false,
    },
    dataPrivate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const headingTitleClass = computed(() => (props.isOuter ? 'heading-outer' : 'heading-inner'))
    const hideSubHeadingClass = computed(() => (props.hideSubHeading ? 'pb-0' : null))
    const containerClass = computed(() => (props.isOuter ? 'heading-container' : null))
    const subHeadingClass = computed(() =>
      props.isOuter && props.subHeading.length > 0 ? 'sub-heading-outer' : 'sub-heading-inner'
    )

    return {
      headingTitleClass,
      hideSubHeadingClass,
      subHeadingClass,
      containerClass,
    }
  },
}
</script>

<style lang="postcss" scoped>
.heading-container {
  @apply pb-6 pt-6 px-4;
  @screen sm {
    @apply px-0;
  }
  @screen md {
    @apply max-w-1/2xl m-auto pb-10;
  }
}

.heading-inner {
  @apply type-stp-title text-xl;
  @screen sm {
    @apply text-2xl;
  }
  @screen md {
    @apply text-3xl;
  }
}
.heading-outer {
  @apply text-xl;
  @screen sm {
    @apply text-2xl;
  }
  @screen md {
    @apply text-3xl font-semibold;
  }
}

.sub-heading-inner,
.sub-heading-outer {
  @apply type-caption text-gray pt-4;
}
</style>
