<template>
  <div>
    <div v-for="(item, index) in stepperItems" :key="index" class="flex justify-between">
      <div>
        <!-- Check icon -->
        <i class="summary-icon w-4">
          <AppIcon v-if="item.isItemChecked">
            <IconCheckmark color="white" />
          </AppIcon>
        </i>
      </div>
      <div class="w-full pl-3 pb-4 overflow-hidden">
        <!-- Content -->
        <div class="font-semibold type-caption-bold">{{ item.name }}</div>
        <div class="summary-item-description">
          {{ item.description }}
        </div>
      </div>
      <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
      <AppButton v-if="!item.disabled" theme="icon" class="icon" @click.native="editStep(item.id)">
        <!-- Pencil  -->
        <AppIcon name="Edit">
          <IconEdit class="text-gray-primary" />
        </AppIcon>
      </AppButton>
    </div>
  </div>
</template>

<script>
import { useDashboardStore } from '@/stores/dashboard'

import AppIcon from '@en-ui/components/AppIcon/AppIcon'
import AppButton from '@en-ui/components/AppButton/AppButton'

import { IconCheckmark, IconEdit } from '@moneytransfer.ui/euronet-icons'

import { onMounted, onUnmounted } from '@vue/composition-api'

export default {
  components: {
    AppIcon,
    AppButton,
    IconCheckmark,
    IconEdit,
  },
  emits: ['edit'],
  props: {
    stepperItems: {
      type: Array,
      default: () => [],
    },
    pageName: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const dashboardStore = useDashboardStore()

    const editStep = (id) => {
      emit('edit', true)
      dashboardStore.setStepperActive({ id, targetKey: props.pageName })
    }

    onMounted(() => 
      dashboardStore.isStepperHidden = true
   )
    onUnmounted(() => dashboardStore.isStepperHidden = false)

    return {
      editStep,
    }
  },
}
</script>

<style lang="postcss" scoped>
.summary {
  &-icon {
    @apply inline-block relative border-gray border rounded-full;
    margin-top: 2px;
    min-width: 18px;
    height: 18px;
    @apply flex justify-center items-center;
    border-color: rgba(8, 135, 62, 1);
    background-color: rgba(8, 135, 62, 1);
    & > svg {
      width: 14px;
      height: 14px;
      position: absolute;
    }
  }
}
.summary-item-description {
  @apply text-gray type-caption-xs overflow-hidden;
  text-overflow: ellipsis;
}
</style>
