<template>
  <div class="w-full">
    <!-- Heading -->
    <XeStpHeading v-if="hasItemsFilled && !isEditing" heading="Confirm business purpose" is-outer />

    <AppCard :size="'1/2xl'">
      <!-- Summary page -->
      <template v-if="hasItemsFilled && !isEditing">
        <DashboardSummary :stepper-items="stepperItems" :page-name="pageName" @edit="onEdit" />
      </template>
      <!-- Normal flow -->
      <template v-else>
        <XeStpHeading
          class="pb-10 pt-8"
          :heading="selectedContent.headline"
          :sub-heading="displaySubheadline"
          :hide-sub-heading="isHandlingFollowUpQuestion"
        />

        <!-- Form -->
        <form class="footer-right" @submit.prevent="submit(selectedField)">
          <!-- Fields -->
          <AppDynamicFormField
            v-if="selectedField && !isHandlingFollowUpQuestion"
            :field="selectedField"
            @follow-ups="handleFollowUps($event)"
            @clean-follow-ups="cleanFollowUps"
          />
          <!-- Follow up question field -->
          <template v-if="isHandlingFollowUpQuestion">
            <AppDynamicFormField class="follow-up-fields" :field="currentFollowUpComponent" />
          </template>
          <!-- Footer -->
          <AppCardFooter class="z-50">
            <AppButton :disabled="isSubmitButtonDisabled" type="submit">Save & Continue</AppButton>
          </AppCardFooter>
        </form>
      </template>
    </AppCard>

    <!-- Summary button -->
    <div v-if="hasItemsFilled && !isEditing" class="submit-button-wrapper">
      <AppButton @click="goToPendingActions()">Save & Continue</AppButton>
    </div>
  </div>
</template>

<script>
import { useStore } from '@/composables/useStore'
import { computed, ref, watch, reactive } from '@vue/composition-api'
import { useRouter } from '@/composables/useRouter'
import { useAdditionalInformationForm } from '@/forms/DashboardAdditionalInformationForm'

import AppCard from '@/components/AppCard/AppCard'
import AppDynamicFormField from '@/components/AppDynamicFormField/AppDynamicFormField'
import AppButton from '@/components/AppButton/AppButton'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import XeStpHeading from '@/components/XeStpHeading/XeStpHeading.vue'

import DashboardSummary from '../DashboardSummary/DashboardSummary.vue'

import { useAnalyticsStore } from '@/stores/analytics'
import { useDashboardStore } from '@/stores/dashboard'

export default {
  components: {
    AppButton,
    AppCard,
    AppCardFooter,
    AppDynamicFormField,
    DashboardSummary,
    XeStpHeading,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const dashboardStore = useDashboardStore()

    // Default values
    const isSetupFinished = ref(false)
    const isEditing = ref(false)
    const stpData = dashboardStore.getStpPageById('businessPurpose')

    // Get saved questions and answers
    const questionsAndAnswers = computed(() => dashboardStore.questionsAndAnswers)
    // Get stepper items
    const stepperItems = computed(() => dashboardStore.getStepperItems('businessPurpose'))
    // Get page status
    const completionStatus = computed(() => dashboardStore.getBusinessPurposesCompletionStatus)
    // List of filled items
    const stepValues = computed(() => {
      const questions = questionsAndAnswers.value
      const fields = [...formFields.value]
      const steps = reactive({})
      fields.forEach(
        (item) => (steps[item.id] = questions.find((q) => q.id === item.id)?.value || '')
      )
      return steps
    })
    // Check if items has filled
    const hasItemsFilled = computed(() => {
      return completionStatus.value
    })

    // Setup fields
    const { setup: setupFields, isLoading, formFields } = useAdditionalInformationForm(store)
    // Check for additional info (server values)
    const additionalInformation = computed(() => dashboardStore.additionalInformation)
    // Create fields if this is empty
    if (additionalInformation?.value?.fields?.length > 0) {
      setupFields(additionalInformation.value)
      isSetupFinished.value = true
    }
    // Update fields base on server saved values
    watch(additionalInformation, (data) => {
      if (!isSetupFinished.value) {
        setupFields(data)
        isSetupFinished.value = true
      }
    })

    // Pending action started | Trigger event when form isn't filled yet
    if (!(hasItemsFilled.value && !isEditing.value)) {
      analyticsStore.track({
        event: 'Pending Action Started',
        traits: {
          ActionName: 'Business Purpose',
        },
      })
    }

    // Setup field validation for dropdown custom component
    const isCustomFieldInvalid = ref(false)
    formFields.value.forEach((field, i) => {
      if (field.component.name === 'xeInputDropdownCustomComponent') {
        formFields.value[i]['listeners'] = {
          isCustomFieldInvalid: (isInvalid) => (isCustomFieldInvalid.value = isInvalid),
        }
      }
    })

    // Sync stepper active item with content
    const selectedContent = computed(() => {
      const activeItem = stepperItems?.value?.find((item) => item.activeItem)
      if (activeItem) {
        return activeItem.content?.find((e) => e)
      }
      return {}
    })
    const otherQuestionRequestText = computed(() => dashboardStore.getOtherQuestionRequestText)
    const otherQuestionDisplayableIds = computed(
      () => dashboardStore.pendingActionsConfig?.otherQuestionDisplayableIds
    )
    const isSelectedContentOther = computed(() =>
      otherQuestionDisplayableIds.value?.includes(selectedContent.value?.id)
    )
    const displaySubheadline = computed(() =>
      isSelectedContentOther.value
        ? otherQuestionRequestText.value
        : selectedContent.value.subHeadline
    )
    // Select field checking active item
    const selectedField = computed(() => {
      // Check total steps in page
      const activeItem = stepperItems?.value?.find((item) => item.activeItem)

      if (activeItem) {
        const field = formFields?.value?.find((field) => field.id === activeItem.id)
        // Remove label
        if (field) {
          field.label = ''
          field.props.dropdownLabel = ''
          field.props.multiSelectLabel = ''
        }
        return field
      }
      return false
    })

    // Follow up process
    const followUps = ref(null)
    const currentFollowIndex = ref(null)
    const currentFollowUpComponent = computed(() => {
      if (followUps.value && currentFollowIndex.value >= 0) {
        const currentFollowUpName = followUps.value[currentFollowIndex.value]
        // Applies custom question personalization according to design
        const followUpField = formFields.value.find((field) => field.id === currentFollowUpName)
        if (followUpField?.id === 'timeInIndustry') {
          // Time In Industry helpers
          followUpField.props.dropdownLabel =
            'Let us know how many years your company has in its current domain.'
        }
        if (followUpField?.id === 'isHighRiskCustomer') {
          // Is high risk customer helpers
          followUpField.props.dropdownLabel =
            'Let us know if you offer your products or services to Government, Charitable, or Religious organizations ?'
        }
        return followUpField
      }
      return null
    })

    const isHandlingFollowUpQuestion = computed(() => {
      // Enables follow up only for this question
      if (isOnFollowUpCategory.value) {
        if (typeof currentFollowIndex.value === 'number') {
          return followUps.value && currentFollowIndex.value >= 0
        }
      }
      return false
    })

    const handleFollowUps = (values) => {
      initializeFollowUps(values, null)
    }

    const isOnFollowUpCategory = computed(() => {
      return activeStepperItem?.value?.id === 'generateBusiness' || false
    })

    const cleanFollowUps = () => {
      if (isOnFollowUpCategory.value) {
        initializeFollowUps(null, null)
      }
    }

    const initializeFollowUps = (values, index) => {
      followUps.value = values
      currentFollowIndex.value = index
    }

    const handleFollowUpSave = async () => {
      await preserveData(currentFollowUpComponent.value)
      if (currentFollowIndex.value === followUps.value.length - 1) {
        if (isEditing.value) {
          isEditing.value = false
        } else {
          cleanFollowUps()
        }
        nextStep(selectedField.value)
      } else {
        currentFollowIndex.value += 1
      }
    }

    const shouldAskFollowQuestion = computed(() => {
      if (isOnFollowUpCategory.value) {
        if (currentFollowIndex.value === null && followUps.value?.length > 0) {
          return true
        }
      }
      return false
    })

    const enterFollowUpMode = () => (currentFollowIndex.value = 0)

    // Follow up reset on stepper change
    const activeStepperItem = computed(() => dashboardStore.getActiveStepperItem)

    watch(activeStepperItem, (_newValue, oldValue) => {
      if (oldValue?.id === 'generateBusiness') {
        // This resets only only the follow index when on Generate Business question
        currentFollowIndex.value = null
      }
    })

    const isSubmitButtonDisabled = computed(() => {
      if (isHandlingFollowUpQuestion.value) {
        // Handles submit disabled button on follow up questions
        return currentFollowUpComponent.value.value ? false : true
      } else {
        if (selectedField?.value?.id === 'generateBusiness') {
          if (selectedField?.value?.props?.options) {
            const optionHasValidation =
              selectedField?.value?.props?.options
                .filter((option) => {
                  return option.validation?.regex
                })
                .map((option) => {
                  return option.value
                })
                .some((value) => selectedField.value?.value?.startsWith(value)) || false
            if (!optionHasValidation) {
              return selectedField?.value?.validation?.$anyInvalid || false
            } else {
              // Handles validation on nested options
              const [selectedOption, value] = selectedField.value.value.split(' - ')
              if (!selectedOption | !value) {
                // Disables field if missing option or value
                return true
              }
              const option = selectedField?.value?.props?.options.find(
                (option) => option.value === selectedOption
              )
              const regexValue =
                typeof option.validation?.regex === 'string'
                  ? option.validation.regex
                  : option.validation.regex.pattern
              if (regexValue) {
                const regex = new RegExp(regexValue)
                const valid = regex.test(value)
                return !valid
              }
              return true
            }
          }
        } else {
          // TODO: Improve this
          if (selectedField?.value?.id === 'purpose' && selectedField?.value?.value === 'Other') {
            // Reset validation
            selectedField.value.value = ''
          }
          return selectedField?.value?.validation?.$anyInvalid || false
        }
      }
    })

    const onEdit = (isEdit) => {
      isEditing.value = isEdit
    }

    // Submit question and stepper position
    const submit = async (field) => {
      switch (true) {
        case isHandlingFollowUpQuestion.value:
          // Handles the follow Up Question Logic
          await handleFollowUpSave()
          break
        case shouldAskFollowQuestion.value:
          // Saves the field and enters the follow up flow
          await preserveData(field)
          enterFollowUpMode()
          break
        case isEditing.value:
          // Out of edition and save
          isEditing.value = false
          await preserveData(field)
          nextStep(field)
          break
        default:
          // Save the field and continue
          await preserveData(field)
          nextStep(field)
      }
    }

    const nextStep = async (field) => {
      await dashboardStore.setStepperValue({
        itemId: field.id,
        targetKey: 'businessPurpose',
        value: field.value,
        setChecked: true
      })
    }
    // Save single question
    const preserveData = async (selectedField) => {
      const selectedItem = additionalInformation.value?.fields?.find(
        (field) => field.id === selectedField.id
      )
      // Amplitude events
      const mappedEventTraits = stpData?.eventOptions
      let event = mappedEventTraits[selectedField.id]?.eventName || null
      let traits = {}
      // Check the destination of countries in order to send the country name value
      const destinationOfFunds =
        selectedField.id === 'destinationCountries'
          ? dashboardStore.getFundDestinationCountriesByIsoCode(selectedField.value)
          : null
      if (event) {
        // Set event prop value
        traits[mappedEventTraits[selectedField.id].eventProp] = destinationOfFunds
          ? destinationOfFunds.join(',')
          : selectedField.value
        // Send the event
        analyticsStore.track({
          event,
          traits,
        })
      }
      // Save question
      dashboardStore.setQuestionAndAnswerById({
        id: selectedField.id,
        value: selectedField.value,
        idText: selectedItem.displayname,
      })
    }

    const goToPendingActions = () => {
      analyticsStore.track({
        event: 'Business Purpose Confirmed',
      })
      router.push({ name: 'PendingActions' })
    }
    const pageName = ref('businessPurpose')

    return {
      questionsAndAnswers,
      formFields,
      additionalInformation,
      isSubmitButtonDisabled,
      isLoading,
      submit,
      goToPendingActions,
      stepValues,
      stepperItems,
      selectedField,
      selectedContent,
      hasItemsFilled,
      handleFollowUps,
      cleanFollowUps,
      currentFollowUpComponent,
      followUps,
      currentFollowIndex,
      isHandlingFollowUpQuestion,
      handleFollowUpSave,
      shouldAskFollowQuestion,
      nextStep,
      activeStepperItem,
      isEditing,
      onEdit,
      pageName,
      stpData,
      enterFollowUpMode,
      displaySubheadline,
    }
  },
}
</script>

<style lang="postcss" scoped>
.type-stp-title {
  @apply text-xl pt-0;
  @screen sm {
    @apply text-2xl pt-8;
  }
  @screen md {
    @apply text-3xl;
  }
}
.type-stp-subheadline {
  @apply pt-4 pb-6;
  @screen sm {
    @apply type-caption text-gray pt-6 pb-10;
  }
}
.card {
  /deep/ .card-content {
    @apply pt-6;
    @screen sm {
      @apply pt-12;
    }
  }
}

.summary-title {
  @apply type-h2 pt-6 px-4;
  @screen sm {
    @apply type-h1 pb-8 px-0;
  }
  @screen md {
    @apply type-stp-title m-auto pb-12;
    max-width: 40rem;
  }
}

.submit-button-wrapper {
  @apply hidden;

  @screen sm {
    @apply flex justify-end mt-6;
  }

  @screen md {
    @apply mt-10 max-w-1/2xl mx-auto;
  }
}

.follow-up-fields {
  >>> .input-label {
    @apply pt-4 pb-6;
    @screen sm {
      @apply type-caption text-gray pt-6 pb-10;
    }
  }
}
</style>
